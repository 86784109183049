/**
 * Get cats pictures
 * @param {number} length
 * @returns {Promise<Array<{ title: string, url:string }>}
 */
export const getImages = (length = 10) => {
  return fetch(`https://api.thecatapi.com/v1/breeds`)
    .then((response) => response.json())
    .then((response) => {
      const images = [
          {description: '1', url: '/images/statut/Statut_USP_01.jpg'},
          {description: '2', url: '/images/statut/Statut_USP_02.jpg'},
          {description: '2', url: '/images/statut/Statut_USP_03.jpg'},
          {description: '2', url: '/images/statut/Statut_USP_04.jpg'},
          {description: '2', url: '/images/statut/Statut_USP_05.jpg'},
          {description: '2', url: '/images/statut/Statut_USP_06.jpg'},
          {description: '2', url: '/images/statut/Statut_USP_07.jpg'},
          {description: '2', url: '/images/statut/Statut_USP_08.jpg'},
          {description: '2', url: '/images/statut/Statut_USP_09.jpg'},
          {description: '2', url: '/images/statut/Statut_USP_10.jpg'}
      ];
      // response.forEach((c) => {
      //   const title = c?.description;
      //   const url = c?.image?.url;
      //
      //   images.push({ title, url });
      // });

      return images.slice(0, length); // remove the extra cats
    });
};
